.dashboard {
    display: flex;
    background: rgb(5, 5, 5);
    @include remove-previous-margin-padding;
    padding: 20px 20px 20px 20px;
    .page__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include basic-shadow-gold;
        @include remove-previous-margin-padding;
        padding: 30px;
        width: 100%;
        height: auto;
        left: 0;
        top: 0;
        z-index: 1;
        position: relative;
        background: rgba(5, 5, 5, 0.9);
        border-radius: 5px;
        z-index: 1;
        color: white;

        &::before {
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: url("../../../assets/images/stripes_rotated.png");
            background-size: 100px;
            @include remove-previous-margin-padding;
            z-index: +1;
            position: absolute;
            @include basic-transition;
        }

        .inputs__container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        a {
            color: $primary;
            z-index: +2;
        }

        div {
            padding: 10px;
            z-index: +2;
        }

        .name {
            font-size: 1.2em;
            z-index: +2;
        }

        .email {
            font-size: 1.2em;
            z-index: +2;
        }

        .dropdown__taskform {
            label {
                margin-right: 10px;
            }
        }

        input,
        .input,
        select {
            color: white;
            background: linear-gradient(180deg, rgba(5, 5, 5, 1) 0%, rgba(0, 0, 0, 1) 100%);
            z-index: +2;
            margin-bottom: 10px;
            padding: 10px 15px;
            border: none;
            outline: none;
            border-radius: 5px;
            outline: 1px solid rgb(100, 100, 100);

            &:hover {
                background: linear-gradient(180deg, rgba(5, 5, 5, 1) 0%, rgba(0, 0, 0, 1) 100%);
            }

            option {
                background-color: rgb(15, 15, 15);
                padding: 10px;
                color: white;

                &:hover {
                    background: $primary;
                }
            }
        }

        button {
            z-index: +2;
            transition: color 200ms, text-shadow 200ms, background 200ms, background-image 200ms;
            text-decoration: none;
            display: block;
            text-align: center;
            color: #363a3c;
            background: url("../../../assets/images/button_huge_popup.png") no-repeat center center scroll;
            width: 198px;
            height: 63px;
            font-size: 1.1em;
            cursor: pointer;
            text-shadow: 0.018cm 0.031cm 0 #ffffff;
            position: relative;
            border: none;
            font-family: "Bender-Bold";

            &:hover {
                background: url("../../../assets/images/button_huge_popup_hover.png") no-repeat center center scroll;
            }
        }

        .level {
            border-radius: 5px;
            outline: 1px solid rgb(150, 150, 150);
            background: linear-gradient(180deg, rgba(5, 5, 5, 1) 0%, rgba(0, 0, 0, 1) 100%);
            margin-bottom: 10px;

            span {
                color: $primary;
            }
        }

        .largeFont {
            font-size: 10em;
        }
    }
}
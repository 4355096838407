.login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgb(15,15,15);

    .login__container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        @include basic-shadow-gold;
        @include remove-previous-margin-padding;
        padding: 30px;
        height: auto;
        left: 0;
        top: 0;
        z-index: 1;
        position: relative;
        background: rgba(5, 5, 5, 0.9);
        border-radius: 5px;
        z-index: 1;
        color: white;

        &::before {
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: url("../../../assets/images/stripes_rotated.png");
            background-size: 100px;
            @include remove-previous-margin-padding;
            z-index: +1;
            position: absolute;
            @include basic-transition;
        }


        * {
            position: relative;
            z-index: +2;
        }

        h1 {
            text-align: center;
            color: #9a8866;
            font-family: Bender;
            word-break: break-word;
            text-transform: uppercase;
            font-size: 3em;
            display: inline-block;
            background: url("../../../assets/images/h2_bg.png") no-repeat 50% scroll;
            -webkit-background-size: contain;
            -moz-background-size: contain;
            background-size: contain;
            -o-background-size: contain;
        }
        input {
            color: white;
            background: linear-gradient(180deg, rgba(5, 5, 5, 1) 0%, rgba(0, 0, 0, 1) 100%);
            z-index: +2;
            margin-bottom: 10px;
            padding: 10px 15px;
            border: none;
            outline: none;
            border-radius: 5px;
            outline: 1px solid rgb(100, 100, 100);
            &:-webkit-autofill {
                background: linear-gradient(180deg, rgba(5, 5, 5, 1) 0%, rgba(0, 0, 0, 1) 100%);
            }
          }
        button {
            z-index: +2;
            transition: color 200ms, text-shadow 200ms, background 200ms, background-image 200ms;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #363a3c;
            background: url("../../../assets/images/button_huge_popup.png") no-repeat center center scroll;
            width: 198px;
            height: 63px;
            font-size: 1.1em;
            cursor: pointer;
            text-shadow: 0.018cm 0.031cm 0 #ffffff;
            position: relative;
            border: none;
            font-family: "Bender-Bold";

            &:hover {
                background: url("../../../assets/images/button_huge_popup_hover.png") no-repeat center center scroll;
            }

            &.login__google {
                width: 250px;
                background-size: 100%;
            }

            img {
                width: 20px;
                margin-right: 7.5px;
                filter: drop-shadow(0.018cm 0.031cm 0 #ffffff);
            }
        }
        div {
            margin-top: 10px;
            color: white;
            a {
                color: $primary;
            }
        }
    }
}
.register {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgb(15,15,15);

    .register__container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        @include basic-shadow-gold;
        @include remove-previous-margin-padding;
        padding: 30px;
        height: auto;
        left: 0;
        top: 0;
        z-index: 1;
        position: relative;
        background: rgba(5, 5, 5, 0.9);
        border-radius: 5px;
        z-index: 1;
        color: white;

        &::before {
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: url("../../../assets/images/stripes_rotated.png");
            background-size: 100px;
            @include remove-previous-margin-padding;
            z-index: +1;
            position: absolute;
            @include basic-transition;
        }


        * {
            position: relative;
            z-index: +2;
        }

        h1 {
            text-align: center;
            color: #9a8866;
            font-family: Bender;
            word-break: break-word;
            text-transform: uppercase;
            font-size: 3em;
            display: inline-block;
            background: url("../../../assets/images/h2_bg.png") no-repeat 50% scroll;
            -webkit-background-size: contain;
            -moz-background-size: contain;
            background-size: contain;
            -o-background-size: contain;
        }
        input {
            color: white;
            background: linear-gradient(180deg, rgba(5, 5, 5, 1) 0%, rgba(0, 0, 0, 1) 100%);
            z-index: +2;
            margin-bottom: 10px;
            padding: 10px 15px;
            border: none;
            outline: none;
            border-radius: 5px;
            outline: 1px solid rgb(100, 100, 100);
            &:-webkit-autofill {
                background: linear-gradient(180deg, rgba(5, 5, 5, 1) 0%, rgba(0, 0, 0, 1) 100%);
            }
          }
        button {
            z-index: +2;
            transition: color 200ms, text-shadow 200ms, background 200ms, background-image 200ms;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #363a3c;
            background: url("../../../assets/images/button_huge_popup.png") no-repeat center center scroll;
            width: 198px;
            height: 63px;
            font-size: 1.1em;
            cursor: pointer;
            text-shadow: 0.018cm 0.031cm 0 #ffffff;
            position: relative;
            border: none;
            font-family: "Bender-Bold";

            &:hover {
                background: url("../../../assets/images/button_huge_popup_hover.png") no-repeat center center scroll;
            }
            
            &.register__google {
                width: 250px;
                background-size: 100%;
            }

            img {
                width: 20px;
                margin-right: 7.5px;
                filter: drop-shadow(0.018cm 0.031cm 0 #ffffff);
            }
        }
        div {
            margin-top: 10px;
            color: white;
            a {
                color: $primary;
            }
        }
    }
}

@import "./admin";
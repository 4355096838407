.backtohome {
    margin-bottom: 10vh;
}

.disabled {
    opacity: 0.2;
    pointer-events: none;
}

.empty-field-message {
    user-select: none;
    border-radius: 5px;
    outline: 1px solid #662d2d;
    color: black solid #662d2d;
    color: black;
    background: #FFCCCC;
    position: relative;
}

.backButtonUni {
    z-index: +2;
    transition: color 200ms, text-shadow 200ms, background 200ms, background-image 200ms;
    text-decoration: none;
    text-align: center;
    color: #363a3c;
    background: url("../../assets/images/button_huge_popup.png") no-repeat center center scroll;
    width: 198px;
    height: 63px;
    font-size: 1.1em;
    cursor: pointer;
    text-shadow: 0.018cm 0.031cm 0 #ffffff;
    position: relative;
    border: none;
    font-family: "Bender-Bold";
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 15px;
    height: 50px !important;
    z-index: +2;
    margin-bottom: 20px;
    &:hover {
        background: url("../../assets/images/button_huge_popup_hover.png") no-repeat center center scroll;
    }
}
